<template>
  <section class="simulate-container">
    <NavbarSimulate />
    <Simulation
      class="simulate-container--info"
      @showStudentSelection="showSelectionModal = true"
    />
    <v-dialog
      v-model="showSelectionModal"
      persistent
      max-width="480"
      transition="slide-x-transition"
      :content-class="mobile
        ? 'system-dialogs-mobile system-dialogs-mobile--white' : 'system-dialogs system-dialogs--wide'"
    >
      <MultiSelectionModals
        @closeModal="showSelectionModal = false"
      />
    </v-dialog>
  </section>
</template>

<script>
import NavbarSimulate from '@/components/explorer/simulate/NavbarSimulate.vue';
import Simulation from '@/components/explorer/simulate/Simulation.vue';
import MultiSelectionModals from '@/components/templates/MultiSelectionModals.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'SimulateApplication',
  components: {
    NavbarSimulate, Simulation, MultiSelectionModals,
  },
  metaInfo() {
    return {
      title: `${this.$tc('dashboard.breadcrumbs.app_name')} | ${this.$tc('dashboard.breadcrumbs.simulate')}`,
      meta: [],
    };
  },
  data() {
    return {
      showSelectionModal: true,
    };
  },
  computed: {
    ...mapGetters({
      editSimulationModal: 'simulation/editSimulationModal',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    if (this.editSimulationModal === false) {
      this.showSelectionModal = true;
    } else {
      this.showSelectionModal = false;
    }
  },
};
</script>
